import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import './styles.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import RedirectExternal from './components/RedirectExternal';

import Download from './pages/Download';
import Home from './pages/Home';

// ScrollToTop component with useLocation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  // Check for email submission from cookies
  useEffect(() => {
    const cookieSetEmail = Cookies.get('emailSubmitted');
    if (cookieSetEmail) setEmailSubmitted(true);
    const cookieEmail = Cookies.get('email');
    if (cookieEmail) setEmail(cookieEmail);
  }, []);

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<Home emailSubmitted={emailSubmitted} setEmailSubmitted={setEmailSubmitted} email={email} setEmail={setEmail} />}
        />
        <Route
          path="/:file"
          element={<Download emailSubmitted={emailSubmitted} setEmailSubmitted={setEmailSubmitted} email={email} setEmail={setEmail} />}
        />
        <Route path="*" element={<RedirectExternal to="https://conceptc.ca" />} />
      </Routes>
      <Footer emailSubmitted={emailSubmitted} setEmailSubmitted={setEmailSubmitted} email={email} setEmail={setEmail} />
    </Router>
  );
}

export default App;
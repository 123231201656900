// src/axiosInstance.js
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY // API key from environment variables
  }
});

// Optionally add interceptors if needed
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request (you can add additional logic here)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axios';
import Cookies from 'js-cookie';

const EmailPopup = ({ setEmailSubmitted, setEmail, email }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { file } = useParams();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      setErrorMessage(null);
      axiosInstance.post('/submit-email', {
        email: email,
        file: file
      }).then(() => {
        setEmail(email);
        setEmailSubmitted(true);
        Cookies.set('emailSubmitted', 'true', { expires: 365 }); // Save email submission in cookies for 365 days
        Cookies.set('email', email, { expires: 366 });
        setErrorMessage(null); // Clear any previous error messages on success
      }).catch(error => {
        console.error('Error: ', error);
        setErrorMessage('Erreur lors de la soumission de l\'email. Veuillez réessayer.'); // Set error message
      });
    } else {
      setErrorMessage('Veuillez entrer une adresse e-mail valide.'); // Handle empty email case
    }
  };

  return (
    <div className="container popup">
      <h1>Veuillez entrer votre courriel pour télécharger :</h1>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="courriel"
      />
      <button onClick={handleSubmit}>Soumettre</button>
      {errorMessage && <p className="error-notice">{errorMessage}</p>} {/* Display error message if it exists */}
    </div>
  );
};

export default EmailPopup;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EmailPopup from '../components/EmailPopup';
import renderContent from '../components/renderContent';
import axiosInstance from '../axios';
import { Helmet } from 'react-helmet';

const Metadata = ({ title, description, image, robots = "index, follow"}) => {
  const url = window.location.href;

  return (
    <Helmet>
      <title>{`${title} - Outils par Concept C`}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${window.location.origin}/${image}`} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Outils par Concept C" />  
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

const Download = ({ emailSubmitted, setEmailSubmitted, email, setEmail }) => {
  const navigate = useNavigate();
  const { file } = useParams();
  const [downloading, setDownloading] = useState(false);
  const [tool, setTool] = useState([]); // State for tools list
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (file) { // Make sure file is available
      const fetchTool = async () => {
        try {
          const response = await axiosInstance.get(`/${file}`);
          setTool(response.data); // Update tools list
        } catch (error) {
          console.error('Failed to fetch tools:', error);
        } finally {
          setLoading(false); // Hide loading animation once data is loaded
        }
      };
  
      fetchTool();
    }
  }, [file]);

  useEffect(() => {
    if (tool.css) {
      const style = document.createElement('style');
      style.innerHTML = tool.css;
      document.head.appendChild(style);

      // Cleanup when the component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [tool.css]);

  if(loading){
    return (
      <div className="loading">
            <div className="loading-spinner"></div>
            <div className="loading-text">En chargement</div>
          </div>
    );
  }

  return (
    <div className="single main-content">
      <Metadata title={tool.name} description={tool.description} image={tool.image} robots={tool.robots}/>
      {!emailSubmitted ? (
        <EmailPopup setEmailSubmitted={setEmailSubmitted} setEmail={setEmail} email={email} />
      ) : (
        <>
          {file && tool.content ? ( // Safeguard for file and content
            renderContent(tool.content, file, downloading, setDownloading, email, navigate)
          ) : (
            <div className="container">
              <p>No content available</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Download;
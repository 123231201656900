// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './WordPressMenu'
import './css/header.scss';

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <Link to="/">
          <img src="/CONCEPTC_Logo2022.png" alt="Logo" className="logo" />
        </Link>
        <Menu/>
      </div>
    </header>
  );
};

export default Header;
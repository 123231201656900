import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectExternal = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // or return a loading spinner while the redirect is happening
};

export default RedirectExternal;
import React, { useEffect, useState } from 'react';
import './css/menu.scss'; // Assuming you're going to create this CSS file for styling

const WordPressMenu = () => {
  const [menu, setMenu] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the hamburger menu

  useEffect(() => {
    fetch('https://conceptc.ca/wp-json/conceptc/v1/menus/main-menu')
      .then(response => response.json())
      .then(data => {
        const menuData = buildMenuStructure(data);
        setMenu(menuData);
      })
      .catch(error => console.error('Error fetching menu:', error));
  }, []);

  const buildMenuStructure = (items) => {
    const menuItems = items.filter(item => item.menu_item_parent === "0");

    menuItems.forEach(item => {
      item.children = items.filter(child => String(child.menu_item_parent) === String(item.db_id));
    });

    return menuItems;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu open/close
  };

  return (
    <nav>      
      <div className="main-menu d-none d-md-block">
        <ul className="menu">
          {menu.map(item => (
            <MenuItem key={item.ID} item={item} />
          ))}
        </ul>
      </div>
      <div className="responsive-menu d-block d-md-none">
        <div className="menu-toggle" onClick={toggleMenu}>
          <span className="hamburger-icon">&#9776;</span> {/* Hamburger Icon */}
        </div>
        <ul className={`menu ${isMenuOpen ? 'open' : ''}`}> {/* Conditionally apply 'open' class */}
          {menu.map(item => (
            <MenuItem key={item.ID} item={item} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ item }) => {
  const hasChildren = item.children && item.children.length > 0;

  return (
    <li className={hasChildren ? 'menu-item-has-children' : ''}>
      <a href={item.url}>{item.title}</a>
      {hasChildren && (
        <ul className="dropdown">
          {item.children.map(child => (
            <li key={child.ID}>
              <a href={child.url}>{child.title}</a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default WordPressMenu;
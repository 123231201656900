// Footer.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import Cookies from 'js-cookie';
import './css/footer.scss';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);


const Footer = ({ emailSubmitted, setEmailSubmitted, email, setEmail }) => {
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);

  setTimeout(() => {
    ScrollTrigger.refresh();
  }, 100);

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger:".footer",
        start: "top 90%",
        toggleActions:"play none none reverse"
      }
    });

    tl.fromTo(".footerbar1",
      {opacity:0, y:0},
      {opacity:1, y:-200, duration:1}
    )
    tl.fromTo(".footerbar2",
      {opacity:0, y:0},
      {opacity:1, y:-100, duration:1}
    )

    return () => {
      tl.kill();
    };

  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleClick = () => {
    window.location.href = 'https://conceptc.ca/contact';
  }

  const handleNewletterSubmit = () => {    
    setMessage(null);
    setStatus(null);

    if (validateEmail(email)) {
      axiosInstance.post('/submit-email', {
        email: email,
      }).then(() => {
        setEmail(email);
        setEmailSubmitted(true);
        Cookies.set('emailSubmitted', 'true', { expires: 365 }); // Save email submission in cookies for 365 days
        Cookies.set('email', email, { expires: 366 });
        setMessage('Inscription à la l\'infolettre réussie ! Merci de vous être inscrit. Vous recevrez bientôt nos dernières actualités dans votre boîte de réception.'); // Set error message
        setStatus('success');

      }).catch(error => {
        console.error('Error: ', error);
        setMessage('Erreur lors de la soumission de l\'email. Veuillez réessayer.'); // Set error message
        setStatus('erreur');
      });
    } else {
      setMessage('Veuillez entrer une adresse e-mail valide.'); // Handle empty email case
      setStatus('erreur');
    }
  }


  return (
    <div className="footer">
      <div className="upper-footer">
        <span className="footerbar footerbar1"/>
        <span className="footerbar footerbar2"/>
        <div>
          <div className="infolettre">
            <h3>Plus de nouvelles, <br/>d'informations pertinentes et de <br/>mini-articles sur le pouce</h3>
            <div className="newletter-form">
              <input className="newletter-input" type="email" placeholder="Inscrivez-vous à notre infolettre avec votre courriel" onChange={(e) => setEmail(e.target.value)}/>
              <button onClick={() => handleNewletterSubmit()}>M'inscrire</button>
            </div>
            {message && <p className={`message ${status}-notice`}>{message}</p>}
          </div>
          <div className="contact">
            <span className="circle"></span>
            <h3>Un projet qui vous brûle les lèvres?</h3>
            <button onClick={() => handleClick()}>Contactez-nous, on veut vous entendre!</button>
          </div>
        </div>
      </div>
      <footer className="lower-footer">
        <div className="row">
          <div className="col-12 col-md-6">
            <img src="/ebook-footer-white.png" alt="Logo" className="logo" />
          </div>
          <div className="col-12 col-md-6">
            <ul className="socials">
              <li><a href="https://www.facebook.com/conceptc" target="_blank" rel="noopener noreferrer">Fb.</a></li>
              <li><a href="https://www.instagram.com/conceptc.marketing/" target="_blank" rel="noopener noreferrer">Inst.</a></li>
              <li><a href="https://www.linkedin.com/company/concept-c" target="_blank" rel="noopener noreferrer">Li.</a></li>
            </ul>
            <p className="copywright">&copy; 2024 Concept C. Tous droits réservés.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import renderContent from '../components/renderContent';
import axiosInstance from '../axios';
import { ReactComponent as MySVG } from './intro.svg';
import { Helmet } from 'react-helmet';

const Metadata = () => {
  const url = window.location.href;
  const description = "Téléchargez gratuitement des outils marketing essentiels pour optimiser votre stratégie en ligne et améliorer vos performances digitales.";

  return (
    <Helmet>
      <title>Outils par Concept C</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Outils par Concept C" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${window.location.origin}/accueil-fb-agence-creative-concept-c.png`} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Outils par Concept C" />  
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

const Home = ({ emailSubmitted, setEmailSubmitted, email, setEmail }) => {
  const [categories, setCategories] = useState([]); // State for tools list
  const [loading, setLoading] = useState(true); // State for loading animation
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Simulate an async API call to fetch tools
    const fetchTools = async () => {
      try {
        const response = await axiosInstance.get('/');
        setCategories(response.data); // Update tools list
      } catch (error) {
        console.error('Failed to fetch tools:', error);
      } finally {
        setLoading(false); // Hide loading animation once data is loaded
      }
    };

    fetchTools();
  }, []);

  if(loading){
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
        <div className="loading-text">En chargement</div>
      </div>
    );
  }

  return ( 
    <div className="main-content">   
      <Metadata />
      {action === 'merci' ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h1>Merci d'avoir <br/><span className="priority">Téléchargé</span></h1>
                <h2>Ton outil est prêt à être utilisé et on est ravis de te voir avancer dans ta stratégie marketing. </h2>
              </div>
              <div className="col-12 col-md-6 d-none d-md-block">
                <MySVG className="merci"/>
              </div>
            </div>
          </div>
          <div className="intro">
            <div className="container">
              <h2>Besoin d’aide supplémentaire ou d’autres ressources?</h2>
              <p>N’hésite pas à explorer nos autres outils gratuits ou à nous contacter pour des conseils personnalisés. Ensemble, on booste ta visibilité en ligne!</p>
              <p>Un e-mail vous sera également envoyé à {email}, avec un lien vers l’outil pour que vous puissiez y accéder à tout moment.</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h1>Optimise ton <br/><span className="priority">marketing</span> <br/>avec des <br/><span>outils gratuits</span></h1>
                <div className="bullets">
                  <p>gratuit</p>
                  <p>outils</p>
                  <p>stratégie</p>
                  <p>contenu</p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-none d-md-block">
                <MySVG className="headersvg"/>
              </div>
            </div>
          </div>
          <div className="intro">
            <div className="container">
              <h2>On a créé une boîte à outils spécialement pour les entrepreneurs de petites et moyennes entreprises comme toi.</h2>
              <p>Que tu aies besoin d’un calendrier de contenu pour les réseaux sociaux, de maquettes de couverture Facebook ou d’autres ressources pratiques, nos modèles sont là pour t’aider à t’organiser, gagner du temps et améliorer la présence en ligne de ta marque. Télécharge-les maintenant et simplifie ta stratégie de marketing digital!</p>
            </div>
          </div>
        </>
      ) }
      <div className="tool-list">
        <>
        {Array.isArray(categories) && categories.length > 0 ? (
          categories.map((categoryObj, catIndex) => {
            // Extract the category name (like "base tools" or "second tools")
            const categoryName = Object.keys(categoryObj)[0];
            const tools = categoryObj[categoryName];

            return (
              <div key={catIndex} className="category-item">
                <div className="container">
                  <h3 className="category-name">{categoryName}</h3>
                  <div className="tools-list">
                  {Array.isArray(tools) && tools.length > 0 ? (
                    tools.map((tool, index) => (
                      <div key={tool.id} className="tool-item">
                        {renderContent(tool.content)}
                        <Link to={`/${tool.id}`} onClick={handleClick}>
                          <button >Télécharger ici!</button>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <p>Outils non disponibles dans cette catégorie pour le moment.</p>
                  )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h2 className="container">Catégories non disponibles pour le moment, veuillez réessayer plus tard.</h2>
        )}
        </>
    </div>
  </div>
  );
};

export default Home;
import axiosInstance from '../axios';

// Utility function to render content based on JSON structure
const renderContent = (content, file, downloading, setDownloading, email, navigate ) => {
  const handleDownload = (file) => {
    setDownloading(true);
    // Adjust the download URL and pass 'email' inside an object
    axiosInstance.get(`/download/${file}`, {
      params: { email },  // Ensure 'email' is an object
      responseType: 'blob' // This will download the file as a blob
    })
      .then((response) => {
        const contentType = response.headers['content-type']; // Get content type from response headers
        const extension = contentType.split('/')[1];
        // Create a URL for the downloaded file blob
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file}.${extension}`); // Set the file name for download
        document.body.appendChild(link);
        link.click(); // Trigger the download
        setDownloading(false);
        navigate('/?action=merci');
      })
      .catch((error) => {         
        alert('Erreur lors du téléchargement:', error); // Handle the error
      })
      .finally(() => {
        setDownloading(false); // Reset downloading state on error
      });
  }

  return content.map((item, index) => {
    const Tag = item.tag;
    // Handle dangerouslySetInnerHTML for injecting HTML content
    if (item.dangerouslySetInnerHTML) {
      return (
        <Tag
          key={index}
          className={item.class}
          style={item.style}
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      );
    }

    // Handle img tag with attributes
    if (Tag === 'img' && item.attributes) {

      return (
        <Tag
          key={index}
          className={item.class}
          style={item.style}
          src={item.attributes.src}
          alt={item.attributes.alt}
        />
      );
    }

    // Handle button with onClick and disabled attributes
    if (Tag === 'button') {
      if (item.download) {
        return (
            <button
            key={index}
            className={item.class}
            style={item.style}
            onClick={() => handleDownload(file)}  // Properly handle the onClick function
            disabled={item.disabled ? item.disabled : false}  // Handle disabled state
          >
            {downloading ? "En téléchargement..." : item.content}
          </button>
          );
      }
      return (
        <button
          key={index}
          className={item.class}
          style={item.style}
          disabled={item.disabled ? item.disabled : false}  // Handle disabled state
        >
          {item.content}
        </button>
      );
    }

    // Default rendering for other elements
    return (
      <Tag key={index} className={item.class} style={item.style}>
        {Array.isArray(item.content)
          ? renderContent(item.content, file, downloading, setDownloading, email, navigate) 
          : item.content}               
      </Tag>
    );
  });
};

export default renderContent;